var F = Object.defineProperty;
var H = (e, s, t) => s in e ? F(e, s, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: t
}) : e[s] = t;
var R = (e, s, t) => (H(e, typeof s != "symbol" ? s + "" : s, t), t),
  T = (e, s, t) => {
    if (!s.has(e)) throw TypeError("Cannot " + t);
  };
var o = (e, s, t) => (T(e, s, "read from private field"), t ? t.call(e) : s.get(e)),
  c = (e, s, t) => {
    if (s.has(e)) throw TypeError("Cannot add the same private member more than once");
    s instanceof WeakSet ? s.add(e) : s.set(e, t);
  },
  n = (e, s, t, i) => (T(e, s, "write to private field"), i ? i.call(e, t) : s.set(e, t), t);
var L = (e, s, t, i) => ({
    set _(r) {
      n(e, s, r, t);
    },
    get _() {
      return o(e, s, i);
    }
  }),
  m = (e, s, t) => (T(e, s, "access private method"), t);
function j(e, s) {
  return !e || !s ? !1 : new RegExp(e.replace(/([.?+^$[\]\\(){}|/-])/g, "\\$1").replace(/\*/g, ".*")).test(s);
}
function q() {
  return Math.random().toString(36).slice(2);
}
var b, p, C, w, P;
class B {
  constructor(s) {
    c(this, p);
    c(this, w);
    R(this, "subscribers");
    // Needs to be arrow function to preserve `this` context as event handler
    c(this, b, s => {
      s.data.type === "burgerprofiel-embed-msg" && j(this.session.targetOrigin, s.origin) && (s.data.action !== "connect" && s.data.sessionId !== this.session.id || this.subscribers.forEach(t => t(s)));
    });
    this.session = s, this.subscribers = /* @__PURE__ */new Set(), this.session.incoming.addEventListener("message", o(this, b));
  }
  destroy() {
    this.session.incoming.removeEventListener("message", o(this, b)), this.subscribers.clear();
  }
  send(s, ...t) {
    const i = this.session.nextMessageId;
    return this.session.outgoing.postMessage({
      action: s,
      payload: t[0],
      messageId: i,
      sessionId: this.session.id,
      type: "burgerprofiel-embed-msg",
      source: this.session.source
    }, this.session.targetOrigin), i;
  }
  sendReplyTo(s, t, ...i) {
    const r = s.source || this.session.outgoing,
      a = this.session.nextMessageId;
    return r.postMessage({
      action: t,
      payload: i[0],
      messageId: a,
      replyTo: s.data.messageId,
      sessionId: s.data.sessionId,
      type: "burgerprofiel-embed-msg",
      source: this.session.source
    }, this.session.targetOrigin || s.origin), a;
  }
  subscribe(...s) {
    if (s.length === 1) return m(this, p, C).call(this, s[0]);
    const [t, i] = s;
    return m(this, p, C).call(this, r => {
      if (r.data.action === t) return i(r);
    });
  }
  async next(s, {
    timeout: t = 3e4
  } = {}) {
    return m(this, w, P).call(this, {
      condition: i => i.data.action === s,
      timeout: t,
      timeoutMessage: `Timeout waiting for next message of type ${String(s)}`
    });
  }
  async nextReply(s, {
    type: t,
    timeout: i = 3e4
  } = {}) {
    return m(this, w, P).call(this, {
      condition: r => r.data.replyTo === s,
      checkAssertions: r => {
        if (r.data.action === "error") {
          const a = r.data.payload;
          throw new Error(`Received error in reply to ${s}: ${a.message}`);
        } else if (t && r.data.action !== t) {
          const a = String(t),
            E = String(r.data.action);
          throw new Error(`Expected reply of type ${a} to ${s} but got ${E}`);
        }
      },
      timeout: i,
      timeoutMessage: `Timeout waiting for reply${t ? " of type " + String(t) : ""} to message #${s}`
    });
  }
}
b = new WeakMap(), p = new WeakSet(), C = function (s) {
  return this.subscribers.add(s), () => {
    this.subscribers.delete(s);
  };
}, w = new WeakSet(), P = async function ({
  condition: s = () => !0,
  checkAssertions: t = () => {},
  timeout: i = 3e4,
  timeoutMessage: r = "Timeout waiting for next message"
} = {}) {
  return new Promise((a, E) => {
    const O = this.subscribe(M => {
        if (s(M)) try {
          t(M), a(M);
        } catch (A) {
          E(A);
        } finally {
          O(), clearTimeout(k);
        }
      }),
      k = setTimeout(() => {
        O(), E(`${r} (waited ${i}ms)`);
      }, i);
  });
};
var u, g, l, h, y, d, $, f, x, I, S;
class D {
  constructor(s, t) {
    c(this, u, void 0);
    c(this, g, void 0);
    c(this, l, "*");
    c(this, h, void 0);
    c(this, y, void 0);
    c(this, d, "pending");
    c(this, $, 0);
    c(this, f, void 0);
    c(this, x, void 0);
    c(this, I, void 0);
    c(this, S, void 0);
    this.source = s, n(this, g, t), n(this, y, window), n(this, S, s), s === "burgerprofiel-embed-client" ? (n(this, u, q()), n(this, h, window.top || window.parent || window)) : (n(this, u, "unconnected"), n(this, h, window), n(this, $, 1e9)), n(this, f, this.resetConnection());
  }
  get id() {
    return o(this, u);
  }
  get clientId() {
    return o(this, g);
  }
  get targetOrigin() {
    return o(this, l);
  }
  get outgoing() {
    return o(this, h);
  }
  get incoming() {
    return o(this, y);
  }
  get status() {
    return o(this, d);
  }
  get nextMessageId() {
    return ++L(this, $)._;
  }
  get connected() {
    return o(this, f);
  }
  get logPrefix() {
    return o(this, S) === "burgerprofiel-embed-client" ? `[client][${this.id}]` : `[ host ][${this.id}]`;
  }
  setStatus(s, t) {
    var i, r;
    s !== o(this, d) && (o(this, d) === "pending" ? s === "connected" ? (i = o(this, x)) == null || i.call(this) : (r = o(this, I)) == null || r.call(this, t) : n(this, f, this.resetConnection()), n(this, d, s));
  }
  resetConnection() {
    return new Promise((s, t) => {
      n(this, x, s), n(this, I, t);
    });
  }
  connectClient(s) {
    s.source && n(this, h, s.source), n(this, l, s.origin), this.setStatus("connected"), console.debug(`${this.logPrefix} Connection established:`, s);
  }
  connectHost(s) {
    s.source && n(this, h, s.source), n(this, l, s.origin), n(this, u, s.data.sessionId), n(this, g, s.data.payload.id), this.setStatus("connected"), console.debug(`${this.logPrefix} Connection established:`, s);
  }
  error(s) {
    this.setStatus("failed", s), console.error(`${this.logPrefix} ${s}`);
  }
}
u = new WeakMap(), g = new WeakMap(), l = new WeakMap(), h = new WeakMap(), y = new WeakMap(), d = new WeakMap(), $ = new WeakMap(), f = new WeakMap(), x = new WeakMap(), I = new WeakMap(), S = new WeakMap();
export { B as M, D as S, j as m };

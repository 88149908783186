// This file can be replaced during build by using the `fileReplacements` array.
import { createMbpEmbedClient } from '@govflanders/mbp-embed-sdk';
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    env: 'staging',
    mbpClient: createMbpEmbedClient('448397ff-1026-4158-8aec-eb6f0593d7eb'), // acm client id
    devProviders: [],
};
